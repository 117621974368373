import moment from 'moment';
import config from '@/constants/config';

export default {
  methods: {
    totalShiftCost(time , rate) {
      if (!time) return;
      // moment(new Date((date+  ` ` + this.start))


      // var date = '2017-03-13';
      // start = moment(date + ' ' + start);
      // end = moment(date + ' ' + end);
      const formatted_price = time*rate ;
      if (formatted_price<=0) return 0.00
      if (isNaN(formatted_price)) return 0;
      return formatted_price.toFixed(2);
    },
    // totalShiftCost(start, end, rate) {
    //   if (!rate) return;
    //   // moment(new Date((date+  ` ` + this.start))
    //
    //   var date = '2017-03-13';
    //   start = moment(date + ' ' + start);
    //   end = moment(date + ' ' + end);
    //   const formatted_price =
    //     (moment(end).diff(moment(start), 'minutes') / 60) * rate;
    //   if (isNaN(formatted_price)) return 0;
    //   return formatted_price.toFixed(2);
    // },

    timeDifferentHours(start, end, brek) {
      var date = '2017-03-13';
      start = moment(date + ' ' + start);
      end = moment(date + ' ' + end);
      brek = brek/60
      if(brek){
        const timeDef = moment(end).diff(moment(start), 'minutes') / 60;
        const timeDefWithBreak = (timeDef) - (brek)
        if (isNaN(timeDefWithBreak)) return 0;
        return timeDefWithBreak.toFixed(2);
      }
      const timeDef = moment(end).diff(moment(start), 'minutes') / 60;
      if (isNaN(timeDef)) return 0;
      return timeDef.toFixed(2);
    },

    getStatus(date){
      var today = new Date().toLocaleDateString();
      var todayDate = new Date(today)
      var shiftStartDate = new Date(date)
     if(shiftStartDate < todayDate){
        return 'Past'
      }else if(shiftStartDate > todayDate){
        return 'Upcoming'
      }else{
        return 'Active'
      }

    },

    calculateHoursWorked(shift) {
      if (!shift) return 0;
      var total = 0;
      shift.forEach((val) => {
        total += moment(val.end).diff(moment(val.start), 'minutes') / 60;
      });
      return total;
    },

    getUserImage(id, update = false) {
      return `${config.apiUrl}api/profile/user/${id}/image?${
        update ? Date.now() : ''
      }`;
    },

    getWeekDayNumberViaDate(day) {
      let split = day.times[0].start.split(':');
      let weekDay = moment(
        moment()
          .startOf('week')
          .add(day.day_number, 'days')
          .set({
            h: split[0],
            m: split[1],
          })
      )
        .utc()
        .day();
      return weekDay === 0 ? 7 : weekDay;
    },
    getUTCConvertedTime(day_number, time) {
      let split = time.split(':');
      return moment(
        moment()
          .startOf('week')
          .add(day_number, 'days')
          .set({
            h: split[0],
            m: split[1],
          })
      )
        .utc()
        .format('HH:mm:ss');
    },
    getFullName(user) {
      return user.first_name + ' ' + user.last_name;
    },
  },
};
