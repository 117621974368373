export default [

  //salary Staff route

  {
    path: '/my-documents',
    name: 'my-documents',
    component: () => import('@/views/my-documents/index.vue'),
    meta: {
      noAuth: true,
      title: 'My Documents',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    }
  },

  // admin rouete

]
