export default [
  //salary Staff route

  {
    path: '/my-leaves',
    name: 'my-leaves',
    component: () => import('@/views/my-leaves/index.vue'),
    meta: {
      noAuth: true,
      title: 'My Leave',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },
  },

  // admin rouete


  {
    path: '/admin-leaves',
    name: 'admin-leave',
    component: () => import('@/views/admin-leave/index.vue'),
    meta: {
      noAuth: true,
      title: 'Leave Management',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/admin-leaves/history/:id',
    name: 'admin-leave-history',
    component: () =>
      import('@/views/admin-leave/views/leave-history/index.vue'),
    meta: {
      noAuth: true,
      title: 'Leave Management',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },
  },
];
