import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  methods: {
    showSuccessMessage(text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text,
          },
        },
        {
          position: 'top-center',
          timeout: 3000,
        }
      );
    },
    convertAndNotifyError(err) {
      if (!(err.response && err.response.data)) return;
      let errors;
      let errorOut = '<ul>';

      if (err.response.data.errors) {
        errors = err.response.data.errors;
        Object.keys(errors).forEach((error) => {
          const element = errors[error];
          errorOut += `<li>${element[0]}</li>`;
        });
      }

      if (err.response.data.error) {
        errorOut += `<li>${err.response.data.error}</li>`;
      }

      errorOut += '</ul>';
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: errorOut,
          },
        },
        {
          position: 'top-center',
          timeout: 4000,
        }
      );
    },
    notify(variant = null, body = '', title = '') {
      this.$notify(variant, title, body, {
        duration: 4000,
        permanent: false,
      });
    },
    showErrorMessage(text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text,
          },
        },
        {
          position: 'top-center',
          timeout: 4000,
        }
      );
    },
    showLeaveConflictErrorMessage(data) {
      let name = data.data.first_name + ' ' + data.data.last_name;
      let date = this.momentFormat(data.data.start, 'DD-MMM-YY');
      let endDate = this.momentFormat(data.data.end, 'DD-MMM-YY');
      let startTime = this.momentFormat(data.data.start, 'HH:mm');
      let endTime = this.momentFormat(data.data.end, 'HH:mm');
      let type = data.data.type;
      let text = '';
      let time = '';

      if (type == 'role_count') {
        let max_leaves_per_day = data.data.max_leaves_per_day;
        text =
          'Sorry but no more than ' +
          max_leaves_per_day +
          ' of staff can take leave on the same date ';
        time = date;
      } else if (type == 'locked_date') {
        text = 'Cannot take leave on locked day ';
        text += '</br>';
        time = date + ' to ' + endDate;
      } else if (type == 'same_time') {
        text = name + ' added leave already for the given time ';
        text += '</br>';
        time = date + ' ' + startTime + ' - ' + endTime;
      }

      text += time;
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text,
          },
        },
        {
          position: 'top-center',
          timeout: 4000,
        }
      );
    },
  },
};
