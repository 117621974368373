export default {
  apiUrl: `${process.env.VUE_APP_API_URL}`,
  // profileUrl: `${process.env.VUE_APP_PROFILE_APP_URL}`
}

export const apiUrl = `${process.env.VUE_APP_API_URL}api/mp`
export const profileUrl = `${process.env.VUE_APP_PROFILE_APP_URL}`
export const baseApiUrl = `${process.env.VUE_APP_API_URL}api/mp`
export const loginAppUrl = `${process.env.VUE_APP_PROFILE_URL}`;
export const mpAppUrl = `${process.env.VUE_APP_MP_APP_URL}`;
export const pcnAppUrl = `${process.env.VUE_APP_PCN_APP_URL}`;

export const days = [
  {
    day: 'Monday',
    text: 'M',
    day_number: 1
  },
  {
    day: 'Tuesday',
    text: 'T',
    day_number: 2,
  },
  {
    day: 'Wednesday',
    text: 'W',
    day_number: 3
  },
  {
    day: 'Thursday',
    text: 'T',
    day_number: 4
  },
  {
    day: 'Friday',
    text: 'F',
    day_number: 5
  },
  {
    day: 'Saturday',
    text: 'S',
    day_number: 6
  },
  {
    day: 'Sunday',
    text: 'S',
    day_number: 7
  }
]

