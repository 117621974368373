export default [
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('@/views/statistics/index.vue'),
      meta: {
        noAuth: true,
        title: 'Statistics',
      },
    },
   
  ]
