export default [
  {
    path: '/leave-management',
    name: 'leave-management',
    component: () => import('@/views/leave-management/index.vue'),
    meta: {
      noAuth: true,
      title: 'My Leaves',
    },
  },
  // {
  //   path: '/approve-timesheet',
  //   name: 'approve-timesheet',
  //   component: () => import('@/views/admin-timesheet/index.vue'),
  //   meta: {
  //     noAuth: true,
  //     title: 'Timesheets',
  //   },
  // },
];
