export default [
    {
        path: '/medicalpractice',
        name: 'medicalpractice',
        component: () => import('@/views/medicalpractice/index.vue'),
        meta: {
            noAuth: true,
            title: 'Medical Practice',
            tab:'3'
        }
    },
    {
        path: '/medicalpractice/view/document',
        name: 'medicalpractice',
        component: () => import('@/views/medicalpractice/view/documents.vue'),
        meta: {
            noAuth: true,
            title: 'Medical Practice',
        }
    },
    {
        path: '/medicalpractice-connection-requests',
        name: 'medicalpractice',
        component: () => import('@/views/medicalpractice/index.vue'),
        meta: {
            noAuth: true,
            title: 'Medical Practice',
            tab:'4'
        }
    },

    
]
