import profile from '@/apis/modules/profile'
import practice from '@/apis/modules/practice'
import Cookies from 'js-cookie'
import router from '@/router/index'

export default {
  state: {
    current_user_selected_permission:null,
    current_user_data: null,
    current_user_permission: null,
    practice_id:undefined,
    all_practice: null,
    all_pcn: null
  },
  mutations: {

    CLEAR_CURRENT_USER: state => {
      state.current_user_data = undefined
      state.current_user_permission = undefined
      Cookies.remove('current_user_token')
    },
    SET_CURRENT_USER_PERMISSION: (state, value) => {
      state.current_user_permission = value
    },
    SET_CURRENT_PRACTICE: (state, value) => {
      state.current_practice = value
    },

    SET_PRACTICE: (state, value) => {
      state.all_practice = value
    },
    SET_PCN: (state, value) => {
      state.all_pcn = value
    },

    SET_CURRENT_PRACTICE_ID: (state, value) => {
      state.practice_id = value
    },

    SET_SELECTED_CURRENT_USER_PERMISSION: (state, value) => {
      state.current_user_selected_permission = value
    },



    SET_CURRENT_USER: (state, value) => {
      state.current_user_data = value
      Cookies.set('current_user_token', value.token)
    },
    UPDATE_USER_INFO: (state, { displayName, photoURL }) => {
      state.current_user_data.name = displayName
      state.current_user_data.image = photoURL
    },
  
  },
  getters: {
    selectedPermission:state=>state.current_user_selected_permission,
    isLogedIn: state => state.current_user_data !== undefined && state.current_user_data !== null,
    currentUser: state => state.current_user_data,
    permissions: state => state.current_user_permission,
    getPracticeId: state => state.practice_id,
    getCurrentPractice: state => state.current_practice,
    getPracticeAndNetwork:state => state.all_practice,
    getPcnAndNetwork:state => state.all_pcn,

  },
  actions: {
    // setPracticeId: ({ commit },id) => { commit('SET_PRACTICE_ID', id) },

    setCurrentUser: ({ commit }, payload) => { commit('SET_CURRENT_USER', payload) },


    // eslint-disable-next-line no-unused-vars
    async afterLogin({ commit }, fromLogin) {
      if (!fromLogin) await router.replace('/scheduler')
    },
    async autoLogin({ commit }) {
      try {
        let currentUserSelectedPermission = []
        const user = (await profile.getCurrentUser()).data.data
        commit('SET_CURRENT_USER', user)

          const cacheUserData = (await practice.getPracticeDetails())
          if(!JSON. parse(sessionStorage. getItem("currentUserSelectedPermission"))){
            if(cacheUserData.data.data.team.roles.some(e=>e.name === 'practice-admin')){
              currentUserSelectedPermission.push('practice-admin')
            }else{
              currentUserSelectedPermission.push('salaried-staff')
            }
            // cacheUserData.data.data.team.roles.length===2?currentUserSelectedPermission.push('practice-admin'):currentUserSelectedPermission.push('salaried-staff')
            sessionStorage.setItem('currentUserSelectedPermission',JSON. stringify(currentUserSelectedPermission))
          }

          const practiceData = (await practice.getAllPractice()).data.data.map((x)=>({
            name:x.name,
            id:x.id,
          }))
          const pcnData = (await practice.getAllPcn()).data.data
          commit('SET_PRACTICE', practiceData)
          commit('SET_PCN', pcnData)
          commit('SET_SELECTED_CURRENT_USER_PERMISSION', currentUserSelectedPermission)
          commit('SET_CURRENT_USER_PERMISSION', cacheUserData.data.data.team.roles.map(x => x.name))
          commit('SET_CURRENT_PRACTICE', cacheUserData.data.data)

      } catch (e) {
        // await this.dispatch('logout')
      }
    },
    async login({ commit }, payload) {
      const loginData = (await profile.login(payload)).data
      Cookies.set('current_user_time', new Date().getTime())
      commit('SET_CURRENT_USER', loginData)
      commit('UPDATE_USER_INFO', {
        displayName: loginData.data.first_name,
        photoURL: loginData.data.photo_url,
      })
      await this.dispatch('afterLogin')
    },
    async logout({ commit }) {
      try {
        await profile.logout()
      } catch (error) {
        await commit('CLEAR_CURRENT_USER')
      }
      await commit('CLEAR_CURRENT_USER')
    },
    async updateCurrentUserPhotoUrl({ commit }, newUrl) {
      commit('UPDATE_USER_INFO', {
        photoURL: newUrl,
      })
    },
  },
}
