import api from '../axios'

export default {
  login: payload => api.post('/clinicians/login', payload),
  logout: payload => api.post('/logout', payload),
  getCurrentUser: payload => api.get('/api/profile'),
  cfrs: payload => api.get('sanctum/csrf-cookie'),
  getMyUnAvailabilities : (payload) => api.get('api/profile/unavailability')

}
