import api from '../axios';
const resource = '/api/mp/';

export default {
  getPracticeDetails: (payload) => api.get(`${resource}practice`),
  getAllPractice: (payload) => api.get('api/practices'),
  getAllPcn: (payload) => api.get('/api/pcn'),
  editPractice: (payload) => api.post(`${resource}practice`, payload),

  //fileUpload
  getImage: (payload) => api.post(`${resource}practice-admin/files`, payload),
  getImagesList: (filterQuery) =>
    api.get(`${resource}practice-admin/files?${filterQuery}`),
  downloadImage: (id) =>
    api.get(`${resource}practice-admin/files/${id}/download`),
  deleteToFile: (id) => api.delete(`${resource}practice-admin/files/${id}`),

  // staff Profile
  getStaffDocumentList: (id) =>
    api.get(`${resource}salaried-staff/files/${id}/get`),
  storeFileByAdmin: (id, payload) =>
    api.post(`${resource}salaried-staff/files/staff/${id}/store`, payload),
  DownloadStaffImage: (file_id, user_id) =>
    api.get(
      `${resource}salaried-staff/files/${file_id}/staff/${user_id}/download`
    ),
  getStaffLeave: (paginate, page, id) =>
    api.get(
      `${resource}practice-admin/leaves?page=${page}&paginate=${paginate}&filter[user_id]=${id}`
    ),
  getConnectedLocations: (page, paginate, id) =>
    api.get(
      `${resource}salaried-staff/${id}/connected-locations?page=${page}&paginate=${paginate}`
    ),
  getSalariedStaff: (id) => api.get(`${resource}settings/users/${id}`),
  storeSalaryStaffLeave: (id, payload) =>
    api.post(`${resource}salaried-staff/${id}/leaves/store`, payload),

  //user notifications
  getNotifications: (paginate, page) =>
    api.get(`${resource}notifications?page=${page}&paginate=${paginate}`),

  updateNotifications: (id) =>
    api.put(`${resource}notifications/${id}`),

  updateAllNotifications: (payload) =>
    api.put(`${resource}notifications/updateAll/1`,payload),
};
