import Vue from 'vue';
import { ModalPlugin, ToastPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';

import authController from '@/router/auth_controller';
import { SchedulePlugin } from '@syncfusion/ej2-vue-schedule';
import router from './router';
import store from './store';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
// Global Components
import './global-components';
// calendar
// eslint-disable-next-line import/order
// 3rd party plugins
// import '@axios'
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/vue-mixins';
import '@/libs/vue-lodash';
import '@/libs/vee-validation';
import '@/libs/vue-select';
import '@/libs/sweet-alerts';

// Vue sheduler
//Common mixins
import MessagesMixin from '@/libs/mixins/MessagesMixin';

import CommonMixin from '@/mixins/CommonMixin';
import { extend } from 'vee-validate';
import { numeric, required, email, min, max } from 'vee-validate/dist/rules';

Vue.use(VueAxios, axios);

// Axios Mock Adapter
Vue.mixin({ mixins: [CommonMixin, MessagesMixin] });

Vue.use(SchedulePlugin);
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

window.Vapor = require('laravel-vapor');

Vue.config.productionTip = false;

authController.init(router, store);

Vue.mixin({
  mixins: [MessagesMixin],
});

extend('numeric', numeric);

extend('tel', {
  validate: (value) => value.match(/^[0-9,+ ]*$/u),
  message: 'Telephone Number must not contain any letter or special character',
});
extend('min_tel', {
  validate: (value) => value.length > 6,
  message: 'Contact number must contain at least 7 numbers.',
});
extend('max_tel', {
  validate: (value) => value.length < 17,
  message: 'Contact number allowed only 16 numbers ',
});

extend('max_input', {
  validate: (value) => value.length < 12,
  message: 'Only 12 digits are allowed  for Smart Card Number',
});

extend('max_bank_account', {
  validate: (value) => value.length < 9,
  message: 'Account Number allowed only 8 characters ',

});
extend('min_bank_account', {
  validate: (value) => value.length > 7,
  message: 'Account Number should contain 8 characters',
});

extend('max_sort_code', {
  validate: (value) => value.length < 7,
  message: 'Sort code allowed only 6 characters ',
});
extend('min_sort_code', {
  validate: (value) => value.length > 5,
  message: 'Sort Code should contain 6 characters',
});

//webshocket

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKETS_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
  wsPort: 6001,
  forceTLS: false,
  disableStats: true,
});
// cluster: process.env.MIX_PUSHER_APP_CLUSTER,

//leave chart
import Chart from 'vue-frappe-chart';

Vue.use(Chart);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');


