import practice from '@/apis/modules/practice'
export default {
  init(router, store) {
    router.beforeEach(async (to, _, next) => {



     if (!sessionStorage.getItem('practice_id')){
       sessionStorage.setItem('practice_id', to.query.practice_id)
     }
      if (JSON.parse(sessionStorage.getItem('permission')) === null){
        const cacheUserData = (await practice.getPracticeDetails())
        sessionStorage.setItem('permission', JSON.stringify(cacheUserData.data.data.team.roles.map(x => x.name)))
        await store.dispatch('autoLogin')
      }
      if(!store.getters.permissions){
        await store.dispatch('autoLogin')
      }
      
      //     if (!canNavigate(to)) {
      //     if (!isLoggedIn) return next({ name: 'auth-login' })
      //     return next({ name: 'misc-not-authorized' })
      //   }

      const { isLogedIn } = store.getters

      if (to.meta.redirectIfLoggedIn && isLogedIn) {
        next({
          path: '/dashboard',
          replace: true,
        })
      }

      if (!to.meta.noAuth && !isLogedIn) {
        next({
          path: '/',
          replace: true,
        })
      }

      return next()
    })
  },
}
