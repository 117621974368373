export default [
  {
    path: '/chat',
    name: 'scheduler',
    component: () => import('@/views/chat/Chat'),
    meta: {
      noAuth: true,
      title: 'Chat',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/chat_custom1',
    name: 'scheduler',
    component: () => import('@/views/chat/Chat_custom'),
    meta: {
      noAuth: true,
      title: 'Chat',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },
  },
  {
    path: '/chat_custom2',
    name: 'scheduler',
    component: () => import('@/views/chat/Chat_custom2'),
    meta: {
      noAuth: true,
      title: 'Chat',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },
  },
]
