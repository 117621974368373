import Vue from 'vue';
import VueRouter from 'vue-router';
// import errorRoute from './modules/error_route'
import profileRoute from './modules/profile_route';
import staff_bank_route from '@/router/modules/staff_bank_route';
// import patientRoute from './modules/patient_route'
// import itemRoute from './modules/item_route'
// import unitRoute from './modules/unit_route'
// import membersRoute from './modules/members_route'
import schedulerRoute from './modules/calendar_route';
import paymentRoute from './modules/payment_route';
import statisticRoute from './modules/statistics_route';
import auth_route from '@/router/modules/auth_route';
import settings_route from '@/router/modules/settings_route';
import chat_route from '@/router/modules/chat_route';
import medicalpractice_route from '@/router/modules/medicalpractice_route';
import timesheet_route from '@/router/modules/timesheet_route';
import leave_management_route from '@/router/modules/leave_management_route'
import leaves_route from '@/router/modules/leaves_route';
import document_route from '@/router/modules/document_route';
import dashboardRoute from './modules/dashboard_route'

import login from '@/views/auth/login';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },

  routes: [
    ...schedulerRoute,
    ...profileRoute,
    ...paymentRoute,
    ...statisticRoute,
    ...staff_bank_route,
    ...auth_route,
    ...settings_route,
    ...chat_route,
    ...medicalpractice_route,
    ...leaves_route,
    ...document_route,
    ...timesheet_route,
    ...leave_management_route,
    ...dashboardRoute
  ],
});

export default router;
