export default [
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/index.vue'),
      meta: {
        noAuth: true,
        title: 'Settings',
      },
    },
   
  ]
