export default [
  {
    path: '/scheduler',
    name: 'scheduler',
    component: () => import('@/views/scheduler/index.vue'),
    meta: {
      noAuth: true,
      title: 'Rota',
      middleware: (to, from, next) => {
          next()
        }

      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],

    },
  },
  {
    path: '/my-shifts',
    name: 'my-shifts',
    component: () => import('@/views/my-shifts/index.vue'),
    meta: {
      noAuth: true,
      title: 'My Shifts',
      // pageTitle: 'Scheduler',
      // breadcrumb: [
      //   {
      //     text: 'Scheduler',
      //     active: true,
      //   },
      // ],
    },

  },

]
